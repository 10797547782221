import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing DELETE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-delete-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-delete-operation",
        "aria-label": "implementing delete operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing DELETE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-server-side"
        }}>{`1. Implement server-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-test-deleting-a-blog-post"
        }}>{`2. Test deleting a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-test-an-error-case"
        }}>{`3. Test an error case`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-add-the-documentation-service"
        }}>{`4. Add the Documentation service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-check-the-docservice-page"
        }}>{`5. Check the DocService page`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`So far, we created, read, and updated a blog post.
Now, let's implement and make a call to delete a blog post.
Also, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation Service`}</a>{` for testing our blog service.`}</p>
    <TutorialSteps current={6} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/grpc"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/grpc/blog/define-service#6-compile-the-proto-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogNotFoundException.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`GrpcExceptionHandler.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's implement a service method `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{` in the `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{` class.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Add a service method, `}<inlineCode parentName="p">{`deleteBlogPost()`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java",
            "filename": "BlogService.java"
          }}>{`import example.armeria.blog.grpc.DeleteBlogPostRequest;

public class BlogService extends BlogServiceGrpc.BlogServiceImplBase {
  @Override
  public void deleteBlogPost(DeleteBlogPostRequest request, StreamObserver<Empty> responseObserver) {
    try {
      // Simulate a blocking API call.
      Thread.sleep(100);
    } catch (InterruptedException e) {
      throw new RuntimeException(e);
    }

    final BlogPost removed = blogPosts.remove(request.getId());
    if (removed == null) {
      responseObserver.onError(
        new BlogNotFoundException("The blog post does not exist. ID: " + request.getId()));
    } else {
        responseObserver.onNext(Empty.getDefaultInstance());
        responseObserver.onCompleted();
    }
 }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add Armeria's `}<a parentName="p" {...{
            "href": "type://GrpcServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html"
          }}>{`type://GrpcServiceBuilder`}</a>{`'s blocking task executor to make all gRPC methods executed
in the blocking task executor's thread pool.`}</p>
        <p parentName="li">{`By default, service methods are executed on the event loop and are expected to be implemented asynchronously.
To implement blocking logic, call `}<inlineCode parentName="p">{`useBlockingTaskExecutor(true)`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`private static Server newServer(int port) throws Exception {
  final GrpcService grpcService =
          GrpcService.builder().addService(new BlogService())
                               .exceptionMapping(new GrpcExceptionHandler())
                               .useBlockingTaskExecutor(true) // Add this
                               .build();
  ...
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "2-test-deleting-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-test-deleting-a-blog-post",
        "aria-label": "2 test deleting a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Test deleting a blog post`}</h2>
    <p>{`Let's test deleting a blog post.
We'll delete the blog post with ID `}<inlineCode parentName="p">{`1`}</inlineCode>{`, and try retrieving with the same ID to verify it is indeed deleted.
Add a test method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(7)
void deleteBlogPost() {
  final DeleteBlogPostRequest request = DeleteBlogPostRequest.newBuilder()
                                                             .setId(1)
                                                             .build();
  client.deleteBlogPost(request);
  final Throwable exception = catchThrowable(() -> {
    client.getBlogPost(GetBlogPostRequest.newBuilder().setId(1).build());
  });

  final StatusRuntimeException statusException = (StatusRuntimeException) exception;
  assertThat(statusException.getStatus().getCode()).isEqualTo(Code.NOT_FOUND);
  assertThat(statusException)
          .hasMessageContaining("The blog post does not exist. ID: 1");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "3-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-test-an-error-case",
        "aria-label": "3 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Test an error case`}</h2>
    <p>{`Let's test deleting a blog post that does not exist.
Add a test method like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`@Test
@Order(8)
void badRequestExceptionHandlerWhenTryingDeleteMissingBlogPost() throws JsonProcessingException {
  final Throwable exception = catchThrowable(() -> {
    client.deleteBlogPost(DeleteBlogPostRequest.newBuilder().setId(100).build());
  });
  final StatusRuntimeException statusException = (StatusRuntimeException) exception;
  assertThat(statusException.getStatus().getCode()).isEqualTo(Code.NOT_FOUND);
  assertThat(statusException)
          .hasMessageContaining("The blog post does not exist. ID: 100");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "4-add-the-documentation-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-add-the-documentation-service",
        "aria-label": "4 add the documentation service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Add the Documentation service`}</h2>
    <p>{`This time, we'll add Armeria's `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{`.
The Documentation service automatically creates documentation of your service methods, as well as providing means to test out the methods.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method, add a `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` instance and a request example for `}<a parentName="p" {...{
            "href": "/tutorials/grpc/blog/implement-create"
          }}>{`creating blog posts`}</a>{`,
using `}<a parentName="p" {...{
            "href": "type://DocServiceBuilder#exampleRequests(Class,String,Iterable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html#exampleRequests(java.lang.Class,java.lang.String,java.lang.Iterable)"
          }}>{`type://DocServiceBuilder#exampleRequests(Class,String,Iterable)`}</a>{`. Feel free to add more examples for other service methods.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`import com.linecorp.armeria.server.docs.DocService;
import com.linecorp.armeria.server.docs.DocServiceFilter;
import io.grpc.reflection.v1alpha.ServerReflectionGrpc;

final class Main {
  private static Server newServer(int port) throws Exception {
    final BlogPost exampleRequest =
      BlogPost.newBuilder()
              .setTitle("Example title")
              .setContent("Example content")
              .build();
    final DocService docService =
      DocService.builder()
                .exampleRequests(BlogServiceGrpc.SERVICE_NAME,
                                 "CreateBlogPost", exampleRequest)
                .exclude(DocServiceFilter.ofServiceName(
                         ServerReflectionGrpc.SERVICE_NAME))
                .build();

  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enable unframed requests by setting `}<a parentName="p" {...{
            "href": "type://GrpcServiceBuilder#enableUnframedRequests(boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html#enableUnframedRequests(boolean)"
          }}>{`type://GrpcServiceBuilder#enableUnframedRequests(boolean)`}</a>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.
This makes the blog service support requests that are not framed in gRPC wire format.
For us, this enables making calls from a web browser.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`private static Server newServer(int port) throws Exception {
...
final GrpcService grpcService =
        GrpcService.builder()
                   .addService(new BlogService())
                   .enableUnframedRequests(true) // Add this
                   .exceptionMapping(new GrpcExceptionHandler())
                   .useBlockingTaskExecutor(true)
                   .build();
...
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` to the server builder.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`private static Server newServer(int port) throws Exception {
  ...
  return Server.builder()
               .http(port)
               .service(grpcService)
               .serviceUnder("/docs", docService) // Add this
               .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`(Optional) To access the Documentation service result easily, edit the log message in the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java highlight=2",
            "filename": "Main.java",
            "highlight": "2"
          }}>{`public static void main(String[] args) throws Exception {
  ...
  logger.info("Server has been started. Serving DocService at http://127.0.0.1:{}/docs",
              server.activeLocalPort());
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Otherwise, restart the server by running the `}<inlineCode parentName="p">{`Main.main()`}</inlineCode>{` method.`}</p>
        <p parentName="li">{`The server and services are launched successfully if you see this message.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`Server has been started. Serving DocService at http://127.0.0.1:8080/docs
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "5-check-the-docservice-page",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-check-the-docservice-page",
        "aria-label": "5 check the docservice page permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Check the DocService page`}</h2>
    <p>{`Let's test and call our service operations using Armeria's Documentation service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Click the URL `}<a parentName="p" {...{
            "href": "http://127.0.0.1:8080/docs"
          }}>{`http://127.0.0.1:8080/docs`}</a>{` from the log message or open up the URL on a web browser.`}</p>
        <p parentName="li">{`If you see the Document service page, you've successfully launched the `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{` and server.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.558282208588956%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABa0lEQVQoz4VRy07DMBDMTyIhQJwQEi39AOBn+ATEGbUHKGcOPARSy4U2aVo7dRrbiR130G6bKsCBlUbe5+xsEh11Bjg46+Ow0+d3/7SPvZM7dC/u0bt6/InLIc5b6F487NC7GuK4N0A0Gk+wEBpCGsTJEq9vnzu8bPH8MuJ4/BljMpX4mgh8TSXiRLWwRDxTiPJihcbq2mM2i5HEU34bzNMEaZpgns44VipDaTVC8H8Q3bzNscwyLISAMRZKKWRZhqLQ8N7DuQau5XuuVZVr5R2qqkJ0/ZRAigWEEKjretvgdqqpKYTAPuWttRyXpeX+siyhteYcxdHtu8Aq36hy3nODMQbr9ZpBPqn221qeKx6UkshrWGt2S6kn+ogFvN/I3ZxRIc9z9smIhJdtTyJyIkzTNbQOqKqSF5MxoVkpVtFIpkEiLIqCc+0FVKN8XQcIEWBtgHMbdUTKhFobSCkZ9H2awf9AV/3O0U/5BtAtnUfHopw0AAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice grpc docservice start",
              "title": "tutorial blogservice grpc docservice start",
              "src": "/static/086c21a19d49782fc8be80de89682531/a6d36/tutorial_blogservice_grpc_docservice_start.png",
              "srcSet": ["/static/086c21a19d49782fc8be80de89682531/222b7/tutorial_blogservice_grpc_docservice_start.png 163w", "/static/086c21a19d49782fc8be80de89682531/ff46a/tutorial_blogservice_grpc_docservice_start.png 325w", "/static/086c21a19d49782fc8be80de89682531/a6d36/tutorial_blogservice_grpc_docservice_start.png 650w", "/static/086c21a19d49782fc8be80de89682531/e548f/tutorial_blogservice_grpc_docservice_start.png 975w", "/static/086c21a19d49782fc8be80de89682531/c23ad/tutorial_blogservice_grpc_docservice_start.png 1193w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`CreateBlogPost()`}</strong>{` method link in the left panel. You can make calls to the method from the page.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "536px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "77.91411042944786%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAABTklEQVQ4y61UTUvDQBDd/yj4AwR/iTfFm+DFo0dPnkQ8efQmVmhLpBAaTTFpzDbfuzNPdmtCTG2bFgfezrK7vHlvGUZQWYHmC5DMQF8JKMnBzGDiZd4RAqUCv4fgUII/Y3CcwgYz9glRqarXw94KlVKIoggLKRHHMbTSzWWbqE28UaFZgiCA67qYzWbI89zuHceB7/sWnufZc1P8ryIrhEbheDzGcDi0pEbpYDCwxFLKhjjLsn6EYRhaRZPJBKPRyKoxRDVB9y83Wi6Kwlopy7LJRGShtV759K0Kt1XkHdtHbGuDXUlFHxv/qrAP2i4EEaONfaLtTHSuUGkNRV0QFJPtgi5MN/xS+PA4xenFM84vX3Bz+wbkBbSZPGm+nECLDBSnoCgBVepnEtF6y1fXrzg4usPh8T1Ozp6ANAMFEhwl4Dp/zMFeABRVbWSt5W8Wi+XRk6yfAAAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice grpc reqex",
              "title": "tutorial blogservice grpc reqex",
              "src": "/static/acdaa3794824d76c419537e1c463904a/2d920/tutorial_blogservice_grpc_reqex.png",
              "srcSet": ["/static/acdaa3794824d76c419537e1c463904a/222b7/tutorial_blogservice_grpc_reqex.png 163w", "/static/acdaa3794824d76c419537e1c463904a/ff46a/tutorial_blogservice_grpc_reqex.png 325w", "/static/acdaa3794824d76c419537e1c463904a/2d920/tutorial_blogservice_grpc_reqex.png 536w"],
              "sizes": "(max-width: 536px) 100vw, 536px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
        <p parentName="li">{`Note that in the `}<strong parentName="p">{`REQUEST BODY`}</strong>{` section the values specified in the `}<inlineCode parentName="p">{`exampleRequest`}</inlineCode>{` are automatically displayed on the page.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`final BlogPost exampleRequest = BlogPost.newBuilder()
                   .setTitle("Example title")
                   .setContent("Example content")
                   .build();
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click the `}<strong parentName="p">{`SUBMIT`}</strong>{` button, and you'll see the blog post information returned in the right panel.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "650px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "27.607361963190186%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA7klEQVQY032Qy07DMBBF8/+fxYYt7YKXxEMsUtsJJI4TO/HYPqguVCBUrnQ00mjmzqMxWkEp/FX5AXjvKzFGSikXafTgWKLgJbGI4FbBBcH6jF0K45JZEzjnKkfD/9Tc9ZnrW1e52o3s3ybu1cz+JbJ7Fm6ehNamOl22gkjGOo8PG2E9bVvv+YqNnyOTFdycmTwEgS3DVo6UE7mQUqZvPWMXaM3Aw8uBx1fFYOffhuVjgnGCdQUylFx/mnOmVE6FIonZRtwQiTGh3y26t7gl1D+fDQ9Go/sO03VobVBKobSmbVuMMTUfQjg3XNK34SewdNK0crM3nwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "tutorial blogservice grpc return",
              "title": "tutorial blogservice grpc return",
              "src": "/static/69a08798c50da02eed9b5765ac89b63e/a6d36/tutorial_blogservice_grpc_return.png",
              "srcSet": ["/static/69a08798c50da02eed9b5765ac89b63e/222b7/tutorial_blogservice_grpc_return.png 163w", "/static/69a08798c50da02eed9b5765ac89b63e/ff46a/tutorial_blogservice_grpc_return.png 325w", "/static/69a08798c50da02eed9b5765ac89b63e/a6d36/tutorial_blogservice_grpc_return.png 650w", "/static/69a08798c50da02eed9b5765ac89b63e/e548f/tutorial_blogservice_grpc_return.png 975w", "/static/69a08798c50da02eed9b5765ac89b63e/302a4/tutorial_blogservice_grpc_return.png 1080w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
    </ol>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a service method and client method for deleting a blog post.
We've also added `}<a parentName="p" {...{
        "href": "/docs/server-docservice"
      }}>{`Documentation service`}</a>{` to our server.`}</p>
    <p>{`We've come to the end of this tutorial.
Next, try adding more service methods to the tutorial or have a go at developing a service of your own.`}</p>
    <TutorialSteps current={6} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      